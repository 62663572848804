<template>
  <div v-if="loading">
    <Alert status="info">Loading</Alert>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>

import Alert from '@/libs/Feedback/components/Alert.vue';

export default {
  components: { Alert },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
};

</script>
