<template>
  <div role="alert" class="alert" :class="alertClass">
    <template v-if="message">{{ message }}</template>
    <slot v-else></slot>
  </div>
</template>

<script>

export default {
  props: {
    statuses: {
      type: Array,
      default: () => ([
        'danger',
        'primary',
        'secondary',
        'success',
        'warning',
        'info',
        'light',
        'dark',
      ]),
    },
    status: {
      type: String,
      require: true,
    },
    message: {
      type: String,
      required: false,
    },
  },
  computed: {
    alertClass () {
      return `alert-${this.status}`;
    },
  },
};

</script>

<style lang="scss"></style>
