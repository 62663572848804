<template>
  <ul class="pagination awl-pagination">
    <li class="page-item" :class="{ disabled: disabled || value <= 1 }">
      <a v-if="!disabled && value > 1" class="page-link" href="#" @click.prevent="goToFirstPage">
        <span aria-hidden="true"><i class="fas fa-angle-double-left"></i></span>
        <span class="sr-only">Prima pagina</span>
      </a>
      <span v-else class="page-link disabled">
        <span aria-hidden="true"><i class="fas fa-angle-double-left"></i></span>
        <span class="sr-only">Prima pagina</span>
      </span>
    </li>
    <li class="page-item" :class="{ disabled: disabled || value <= 1 }">
      <a v-if="!disabled && value > 1" class="page-link" href="#" @click.prevent="goToPreviousPage">
        <span aria-hidden="true"><i class="fas fa-angle-left"></i></span>
        <span class="sr-only">Pagina precedente</span>
      </a>
      <span v-else class="page-link disabled">
        <span aria-hidden="true"><i class="fas fa-angle-left"></i></span>
        <span class="sr-only">Pagina precedente</span>
      </span>
    </li>
    <li class="page-item" :class="{ active: page === value }" v-for="page in pagesRange" :key="page">
      <a v-if="!disabled && page !== value" class="page-link" href="#" @click.prevent="goToPage(page)">
        {{ page }}
      </a>
      <span v-else class="page-link disabled">
        {{ page }}
      </span>
    </li>
    <li class="page-item" :class="{ disabled: disabled || value === totalPages }">
      <a v-if="!disabled && value < totalPages" class="page-link" href="#" @click.prevent="goToNextPage">
        <span aria-hidden="true"><i class="fas fa-angle-right"></i></span>
        <span class="sr-only">Pagina successiva</span>
      </a>
      <span v-else class="page-link disabled">
        <span aria-hidden="true"><i class="fas fa-angle-right"></i></span>
        <span class="sr-only">Pagina successiva</span>
      </span>
    </li>
    <li class="page-item" :class="{ disabled: disabled || value === totalPages }">
      <a v-if="!disabled && value < totalPages" class="page-link" href="#" @click.prevent="goToLastPage">
        <span aria-hidden="true"><i class="fas fa-angle-double-right"></i></span>
        <span class="sr-only">Ultima pagina</span>
      </a>
      <span v-else class="page-link disabled">
        <span aria-hidden="true"><i class="fas fa-angle-double-right"></i></span>
        <span class="sr-only">Ultima pagina</span>
      </span>
    </li>
  </ul>
</template>

<script>

export default {
  props: {
    value: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    useFirstPageAction: {
      type: Boolean,
      default: false,
    },
    usePreviousPageAction: {
      type: Boolean,
      default: false,
    },
    useNextPageAction: {
      type: Boolean,
      default: false,
    },
    useLastPageAction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    pagesRange () {
      if (this.totalPages === 1) {
        return [1];
      }

      const rangePages = 7;
      const firstPage = Math.max(1, this.value - Math.floor(rangePages / 2));
      const lastPage = Math.min(this.totalPages, firstPage + rangePages);

      const pages = [];
      for (let i = firstPage; i <= lastPage; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  methods: {
    goToFirstPage () {
      this.$emit('page', 1);
    },
    goToPreviousPage () {
      this.$emit('page', this.value - 1);
    },
    goToNextPage () {
      this.$emit('page', this.value + 1);
    },
    goToLastPage () {
      this.$emit('page', this.totalPages);
    },
    goToPage (page) {
      this.$emit('page', page);
    },
  },
};

</script>

<style lang="scss">

.awl-pagination {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: row;
  user-select: none;
  justify-content: center;
  padding: 20px;
  border: 0;
  border-radius: 0;

  .page-item {
    .page-link {
      border: 0;
      width: 32px;
      height: 32px;
      font-size: 16px;
      line-height: 21px;
      margin-left: 10px;

      &.disabled {
        cursor: default;
      }

      &:focus {
        box-shadow: none;
      }
    }

    &.active {
      font-weight: bold;
    }

    &:not(.active) .page-link {
      background: transparent;
    }

    &:first-child .page-link {
      border-radius: 0;
      margin-left: 0;
    }

    &:last-child .page-link {
      border-radius: 0;
    }

    &.active .page-link {
      align-items: center;
      border-radius: 50%;
      display: flex;
      justify-content: center;
    }

    i {
      font-size: 14px;
    }

    a, span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

</style>
