<template>
  <ActionEdit :to="to" :disabled="disabled" btnClasses="btn-secondary" btnClassesMobile="text-secondary" />
</template>

<script>

import actionMixin from '@/libs/Table/components/Actions/mixins/action.js';
import ActionEdit from '@/libs/Table/components/Actions/ActionEdit.vue';

export default {
  mixins: [actionMixin],
  components: {
    ActionEdit,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};

</script>
